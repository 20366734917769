
.content ::v-deep img{
     width: 100%;
 }
 .content {
     margin-top: 20px;

     ::v-deep p {
         img {
             max-width: 500px;
         }
     }
 }
